import isOnline from 'is-online';
import { Component } from 'vue';
import * as config from '@/config';





const getEnv = (key: string): (string|undefined) => process.env[`VUE_APP_${key.toUpperCase()}`];
const isProd = () => getEnv('env') === 'production';
const getConfigEnv = (key: string): any => config.default[key];
const randomInteger = (min: number, max: number): number => Math.floor((Math.random() * ((max - min) + 1)) + min);
const ucWords = (str: string): string => `${str}`.replace(/^(.)|\s+(.)/g, ($1) => $1.toUpperCase());
const ucfirst = (str:string): string => `${str.charAt(0).toUpperCase()}${str.slice(1)}`;
const jsonParse = (json: string): any => JSON.parse(json);
const groupBy = (items, key) => items.reduce(
  (result, item) => ({
    ...result,
    [item[key]]: [
      ...(result[item[key]] || []),
      item,
    ],
  }),
  {},
);

// Vue related
const getComponent = (name: string): Promise<Component> => import(
  /* webpackChunkName: "components/[request]" */ `@/components/${name}.vue`
);
const getView = (name: string): Promise<Component> => import(
  /* webpackChunkName: "views/[request]" */ `@/views/${name}.vue`
);



// Commonly used methods
function addNumberPadding(n, width, z) {
	let znew = z || '0';
	let nnew = n + '';
	return nnew.length >= width ? nnew : new Array(width - nnew.length + 1).join(znew) + nnew;
}

function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

function dateDiff(first, second) {
    // Take the difference between the dates and divide by milliseconds per day.
    // Round to nearest whole number to deal with DST.
    return Math.round((second-first)/(1000*60*60*24));
}

function getOffset( el ) {
	var _x = 0;
	var _y = 0;
	while( el && !isNaN( el.offsetLeft ) && !isNaN( el.offsetTop ) ) {
		_x += el.offsetLeft - el.scrollLeft;
		_y += el.offsetTop - el.scrollTop;
		el = el.offsetParent;
	}
	return { top: _y, left: _x };
}

// getPos
function getElementPosition(el, type) {
	for (var lx=0, ly=0;
		el != null;

		lx += el.offsetLeft, ly += el.offsetTop, el = el.offsetParent);
	return {x: lx,y: ly};
}

function getElementCenterPosition(el, type) {
	var width = el.getBoundingClientRect().width;
	var height = el.getBoundingClientRect().height;

	var elPosition = getOffset(el)
	var centerX = elPosition.left + width / 2;
	var centerY = elPosition.top + height / 2;


	return {x: elPosition.left + centerX, y: elPosition.top + centerY};
}

function convertISOtoLocalTime(iso_date) { // iso_date type: '2022-04-20T01:28'
  let date:any = new Date(iso_date + 'Z')
  let local_date = new Date( date.getTime() - ( date.getTimezoneOffset() * 60000 ) );
  return local_date.toISOString().slice(0, 19)
}

function getFormattedDate(date) {
  const year = date.getFullYear();
  const month = (1 + date.getMonth()).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');

  return `${month}/${day}/${year}`;
}


function  isNumberConvertable(value) {
  // Check if the value is already a number.
  if (typeof value === "number") {
    return true;
  }

  // Try to convert the value to a number.
  const convertedValue = Number(value);

  // Check if the converted value is a valid number.
  if (isNaN(convertedValue)) {
    return false;
  }

  // The value can be converted to a number.
  return true;
}


function getISOStringWithLocalOffset(date) {
  if(!date){
    return date
  }
  const now = new Date();
  const localDate =  date.split('T')
  const tzo = -now.getTimezoneOffset();
  const dif = tzo >= 0 ? '+' : '-';
  const pad = function(num) {
    const norm = Math.abs(Math.floor(num));
    return (norm < 10 ? '0' : '') + norm;
  };

  return localDate[0] + 'T' + pad(now.getHours())
    + ':' + pad(now.getMinutes())
    + ':' + pad(now.getSeconds())
    + dif + pad(tzo / 60)
    + ':' + pad(tzo % 60);
}


function isValidJsonString(str) {
  try {
      JSON.parse(str);
  } catch (e) {
      return false;
  }
  return true;
}



function   randomAssetName(level: String, asset_name: String) {
  const alphabet = 'abcdefghijklmnopqrstuvwxyz';
  const randomLetter = alphabet[Math. floor(Math. random() * alphabet. length)]
  if (asset_name.length % 2 == 0) {
    return level.toLowerCase() == "well"  ? `Well ` + asset_name.substring(0,2).toUpperCase() : `Pad ` + asset_name.substring(0,2).toUpperCase();
  } else {
    return level.toLowerCase() == "well"  ? `Well ` + asset_name.substring(0,1).toUpperCase() : `Pad ` + asset_name.substring(0,1).toUpperCase();
  }

}

function getDaysDiff() {
  let newDate = '06/21/2024';
  const wellName =   localStorage.getItem('activeTasqName')
  if (wellName === 'SANFORD 32N-30C-M') {
    newDate = '04/17/2024'; // Ensure consistent date format MM/DD/YYYY
  }else if(wellName === 'TROUDT 41N-26B-L'){
    newDate = '06/07/2024';
  }

  const targetDate = new Date(newDate).getTime();


  // Get the current date
  const currentDate = new Date().getTime();

// Calculate the time difference in milliseconds
const timeDifferenceMs = currentDate - targetDate;

// Convert the time difference to days
return Math.floor(timeDifferenceMs / (1000 * 60 * 60 * 24));

}


async function isGoodService() {
  /**
   * Checks if the service is good.
   *
   * @returns {boolean} True if the service is good, false otherwise.
   */


  const isMobile = window.innerWidth < 769

  if (isMobile) {
    // @ts-ignore
    const connection = navigator.connection
    const isOnlineApp = await isOnline();
    if(connection){
      const isLowService = connection.effectiveType === "slow-2g" || connection.downlink < 0.5;
      return !isLowService && isOnlineApp;
    }else{
      return isOnlineApp
    }


  } else {
    return true;
  }
}


export {
  addNumberPadding,
  sleep,
  dateDiff,
  getOffset,
  getElementPosition,
  getElementCenterPosition,
  getEnv,
  isProd,
  getConfigEnv,
  randomInteger,
  ucWords,
  ucfirst,
  jsonParse,
  groupBy,
  getComponent,
  getView,
  convertISOtoLocalTime,
  getFormattedDate,
  isValidJsonString,
  isGoodService,
  getDaysDiff,
  isNumberConvertable,
  getISOStringWithLocalOffset,
  randomAssetName

};
